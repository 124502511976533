import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Ratgeber = () => (
  <Layout>
    <SEO 
    title="Kauf-Ratgeber: Höhenverstellbare Schreibtische"
    description="Wertvolle Tipps die du vor dem Kauf eines höhenverstellbaren Schreibtisches beachten solltest." />
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <h1 className="display-3">Ratgeber</h1>
        <p className="lead">Stehschreibtische sind in der Anschaffung teuer, aber eine gute Investition in die eigene Gesundheit.<br />
        Beim Kauf gibt es einige Punkte zu beachten, damit du mit dem Produkt deiner Wahl auch lange Zeit glücklich bist.</p>
      </div>
    </div>

    <div className="container">
      <h2>Maximale Höhe</h2>
      <p>Je nach deiner Größe richtet sich auch die optimale Höhe deines Stehschreibtischs.
    Besonders große Menschen müssen auf die maximale Höhe des Stehschreibtisches Ihrer Wahl acht geben.</p>

      <h2>Antriebsart</h2>
      <p>Generell gibt es zwei Antriebsarten: elektrisch und manuell.</p>
      <p>Elektrische Antriebe haben den Vorteil die Höhe des Stehschreibtisches bequem anpassen zu können.
      Da du an einem Arbeitstag häufiger zwischen stehender und sitzender Position wechseln wirst,
    empfiehlt es bei der Höhenverstellung auf die elektrische Lösung zu setzen.</p>
      <p>Höhenverstellbare Schreibtische mit Kurbelantrieb sind preiswerter in der Anschaffung.
    Jedoch kann das Kurbeln beim häufigen Verstellen der Höhe sehr schnell nervig werden.</p>

      <h2>Maximale Belastungsfähigkeit</h2>
      <p>Das Gewicht von Monitoren, Computer, Werkzeug usw. sollte die maximale Tragfähigkeit deines Stehschreibtisches nicht überschreiten.</p>

      <h2>Speicherfunktion und Apps</h2>
      <p>Eine Speicherfunktion erlaubt elektrisch angetriebene Stehschreibtische mit einem Knopfdruck auf eine voreingestellte Höhe zu fahren.
    Empfehlenswert wenn man des öfteren zwischen Sitzen und Stehen wechselt.</p>
      <p>Premium-Modelle kommen mit Smartphone App, welche erlaubt die Höhe des Tisches zu steuern und Gesundheitsinformationen zu tracken.</p>

      <h2>Garantie</h2>
      <p>Was wenn zum Beispiel ein Antriebsmotor kaputt wird? Die Herstellergarantie kann bei manchen Modellen bis zu 10 Jahren betragen.</p>

      <h2>Größe</h2>
      <p>Wieviel Platz hast du für deinen Stehschreibtisch? Aber noch wichtiger: Wieviel Platz brauchst du um deine Aufgaben zu erledigen?</p>

      <h2>Zusatzausstattung</h2>
      <p>Was benötigst du noch um komfortabel arbeiten zu können?
      Zusatzausstattung ist bei manchen Modellen bereits inkludiert, oder kann auch optional erworben werden.
    Z.B.: Kabelschächte, Computerhalterungen, integrierte Stormverteiler, Akkus, ...</p>
    </div>
  </Layout>
)

export default Ratgeber
